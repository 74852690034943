var Handlebars = require('handlebars');
var ClientModel = require('../models/client');
var ChannelModel = require('../models/channel');
var Utils = require('../trash/utils');
var Menu = require('../templates/include/menu.html');
var Tpl = require('../templates/utm.html');
var bootbox = require('bootbox');
Handlebars.registerHelper('ifEqual', function(v1, v2, options) { if(v1 === v2) return options.fn(this); return options.inverse(this); });

module.exports = Backbone.View.extend({
    initialize: function (options) {
        var that = this;
        this.model = new ClientModel({id: options.pk});
        this.model.fetch()
            .done(function() {
                that.model = Utils.arrayToCollection(that.model);
                that.render();
                that.listenTo(that.model, 'change', that.changeModel)
                NProgress.done();
            });
        Utils.renderMenu(this.model);
    },

    events: {
        'submit .channel-add': 'addChannel',
        'click .channel-remove': 'removeChannel'
    },

    addChannel: function(ev) {
        ev.preventDefault();
        var that = this;
        var data = $(ev.target).serializeObject();
        var channel = new ChannelModel(data);
        channel.save()
            .done(function() {
                that.model.get('channel').add(channel);
                that.render();
            });
    },

    removeChannel: function(ev) {
        ev.preventDefault();
        var that = this;

        bootbox.confirm("Вы точно хотите деактивировать метку?", function(result) {
            if (result) {
                var channel = that.model.get('channel').get($(ev.target).data('id'));
                channel.destroy()
                    .done(function() {
                        that.render();
                    });
            }
        });
    },

    render: function() {
        var that = this;

        var template = Handlebars.compile(Tpl);
        $('#content').html(template({
            obj: this.model.toJSON(),
            utms: this.model.get('utm').toJSON(),
            channel: this.model.get('channel').toJSON()
        }));

        $('.utm').sortable({
            connectWith: "ul",
            stop: function(ev, ui) {
                var utm = that.model.get('utm').get($(ui.item).data('id'));
                utm.set({channel: ui.item.parent('.utm').data('id')});
                utm.save()
                    .done(function() {
                        NProgress.done();
                    });
            }
        });
    },

    deactivate: function () {
        $('.menu-detail').empty();
        $('#content').empty();
        this.stopListening();
        this.undelegateEvents();
        return this;
    }
});
