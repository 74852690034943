var Backbone = require('backbone');
var Handlebars = require('handlebars');
var GoalTpl = require('../templates/goal.html');

module.exports = Backbone.View.extend({
    tagName: 'tr',

    initialize: function (options) {
        this.parentView = options.parentView;
        this.render();
    },

    events: {
        'click .fa-square-o': 'activate',
        'click .fa-check-square-o': 'deactivate',
    },

    activate: function(ev) {
        ev.preventDefault();
        var that = this;
        this.model.set('status', true);
        this.model.save()
            .done(function() {
                that.parentView.render();
            });
    },

    deactivate: function(ev) {
        ev.preventDefault();
        var that = this;
        bootbox.confirm("Вы точно хотите деактивировать цель?", function(result) {
            if (result) {
                that.model.set('status', false);
                that.model.save()
                    .done(function() {
                        that.parentView.render();
                    });
            }
        });
    },

    render: function () {
        var template = Handlebars.compile(GoalTpl);
        this.$el.html(template({obj: this.model.toJSON()}));
    }
});