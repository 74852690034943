var Backbone = require('backbone');
var Handlebars = require('handlebars');
var moment = require('moment');
var Tpl = require('../templates/period.html');
Handlebars.registerHelper('moment', function(context) {
    return context.format('YYYY-MM-DD');
});

module.exports = Backbone.View.extend({
    tagName: 'td',

    events: {
        'click .btn-danger': 'remove',
        'submit .changePeriod': 'change'
    },

    change: function(ev) {
        ev.preventDefault();
        var data = $(ev.target).serializeObject();
        var periods = data.daterange.split(' - ');
        this.model.set({
            d1: moment(periods[0], "YYYY-MM-DD"),
            d2: moment(periods[1], "YYYY-MM-DD")
        });
    },

    remove: function() {
        var that = this;
        bootbox.confirm("Удалить период?", function(result) {
            if (result) {
                that.model.collection.remove(this.model);
                that.model.destroy();
            }
        });
    },

    initialize: function() {
        this.render();
    },

    render: function() {
        var that = this;
        var template = Handlebars.compile(Tpl);
        this.$el.html(template({obj: this.model.toJSON()}));
        this.$el.attr({colspan: 3});

        this.$el.find('input[name=daterange]').daterangepicker({
            opens: "left",
            locale: {
                format: 'YYYY-MM-DD'
            }
        });

        this.$el.find('input[name=daterange]').on('apply.daterangepicker', function(ev, picker) {
            that.change(ev);
        });
    }
});
