var Handlebars = require('handlebars');
var moment = require('moment');

module.exports = (function () {
    function get_key_if_exist(data, key1, key2) {
        return (data[key1] !== undefined) ? parseInt(data[key1][key2]): 0;
    }

    // Получение количества звонков
    Handlebars.registerHelper('getCalls', function(data, key, options) {
        return get_key_if_exist(data, key, 'phone').toLocaleString() ;
    });

    // Получение количества целей
    Handlebars.registerHelper('getGoals', function(data, key, options) {
        return get_key_if_exist(data, key, 'cart').toLocaleString() ;
    });

    // Получение суммы
    Handlebars.registerHelper('getSum', function(data, key, options) {
        var leadsum = get_key_if_exist(data, key, 'phone') + get_key_if_exist(data, key, 'cart');
        return leadsum.toLocaleString();
    });

    // Получение расходов
    Handlebars.registerHelper('getScore', function(data, key, options) {
        return get_key_if_exist(data, key, 'score').toLocaleString() ;
    });
    // Получение суммы за клиента
    Handlebars.registerHelper('getOneScore', function (data, key) {
        var count = get_key_if_exist(data, key, 'phone') + get_key_if_exist(data, key, 'cart');
        var score = get_key_if_exist(data, key, 'score');
        return (count > 0 && score > 0) ? parseInt(score/count).toLocaleString()  : 0;
    });
})();