var Backbone = require('backbone');
var Handlebars = require('handlebars');
var ClientView = require('../views/client');
var ClientModel = require('../models/client');
var ClientsCollections = require('../collections/client');
var ClientsTpl = require('../templates/clients.html');

module.exports = Backbone.View.extend({
    el: $('#content'),

    initialize: function () {
        var that = this;
        this.collection = new ClientsCollections();
        this.collection.fetch()
            .done(function () {
                that.render();
            });
    },

    events: {
        'submit .add_cl': 'addClient',
    },

    addClient: function (ev) {
        ev.preventDefault();
        data = $(ev.target).serializeObject();
        var that = this;
        var client = new ClientModel(data);
        client.save()
            .done(function () {
                var view = new ClientView({model: client});
                that.$el.find('.table tbody').append(view.$el);
                $(ev.target)[0].reset();
            });
    },

    render: function () {
        var that = this;
        var template = Handlebars.compile(ClientsTpl);
        this.$el.html(template());
        this.collection.each(function (model) {
            var view = new ClientView({model: model});
            that.$el.find('.table tbody').append(view.$el);
        });
    },

    deactivate: function() {
        this.$el.empty();
        this.stopListening();
        this.undelegateEvents();
        return this;
    }
});