var $ = require('jquery');
var Backbone = require('backbone');
var Handlebars = require('handlebars');
var moment = require('moment');
var UTMCollection = require('../collections/utm');
var ChannelCollection = require('../collections/channel');
var Menu = require('../templates/include/menu.html');

module.exports = (function () {
    return {
        arrayToCollection: function (model) {
            var collection = new UTMCollection();
            collection.reset(model.get('utm'));
            model.set({utm: collection});

            collection = new ChannelCollection();
            collection.reset(model.get('channel'));
            model.set({channel: collection});
            return model;
        },

        periods: function () {

            return new Backbone.Collection(
                [
                    {
                        d1: moment().startOf('day').subtract(1,'months').date(1),
                        d2: moment().startOf('day').subtract(1,'months').endOf('month')
                    },
                    {
                        d1: moment().startOf('day').date(1),
                        d2: moment().startOf('day')
                    },
                ]
            );
        },

        // Фильтр периодов
        changePeriods : function (key) {
            var prev_year = moment().startOf('year').subtract(1, 'year');
            var month_count = moment().diff(prev_year, 'months');
            var last_year_dates = [];
            for (var i = 0; i < month_count; i++) {
                var period = {
                    d1: moment(prev_year).add(i, 'months').date(1),
                    d2: moment(prev_year).add(i, 'months').endOf('month')
                };
                last_year_dates.push(period);
            }
            var monthsFrom2016 = [];
            for (var i = 0; i < moment().diff(moment("20160101"), 'months') + 1; i++) {
                var period = {
                    d1: moment("20160101").add(i, 'months').date(1),
                    d2: moment("20160101").add(i, 'months').endOf('month')
                };
                monthsFrom2016.push(period);
            }
            var period = {
                d1: moment().startOf('day').date(1),
                d2: moment().startOf('day')
            };
            last_year_dates.push(period);
            var periods = {

                last_2_month : [
                    {
                        d1: moment().startOf('day').subtract(1,'months').date(1),
                        d2: moment().startOf('day').subtract(1,'months').endOf('month')
                    },
                    {
                        d1: moment().startOf('day').date(1),
                        d2: moment().startOf('day')
                    }
                ],

                last_4_month : [
                    {
                        d1: moment().startOf('day').subtract(3,'months').date(1),
                        d2: moment().startOf('day').subtract(3,'months').endOf('month')
                    },
                    {
                        d1: moment().startOf('day').subtract(2,'months').date(1),
                        d2: moment().startOf('day').subtract(2,'months').endOf('month')
                    },
                    {
                        d1: moment().startOf('day').subtract(1,'months').date(1),
                        d2: moment().startOf('day').subtract(1,'months').endOf('month')
                    },
                    {
                        d1: moment().startOf('day').date(1),
                        d2: moment().startOf('day')
                    }
                ],

                last_year : last_year_dates,

                last_7_days : [
                    {
                        d1: moment().startOf('day').subtract(7, 'd'),
                        d2: moment().startOf('day').subtract(7, 'd'),
                    },
                    {
                        d1: moment().startOf('day').subtract(6, 'd'),
                        d2: moment().startOf('day').subtract(6, 'd'),
                    },
                    {
                        d1: moment().startOf('day').subtract(5, 'd'),
                        d2: moment().startOf('day').subtract(5, 'd'),
                    },
                    {
                        d1: moment().startOf('day').subtract(4, 'd'),
                        d2: moment().startOf('day').subtract(4, 'd'),
                    },
                    {
                        d1: moment().startOf('day').subtract(3, 'd'),
                        d2: moment().startOf('day').subtract(3, 'd'),
                    },
                    {
                        d1: moment().startOf('day').subtract(2, 'd'),
                        d2: moment().startOf('day').subtract(2, 'd'),
                    },
                    {
                        d1: moment().startOf('day').subtract(1, 'd'),
                        d2: moment().startOf('day').subtract(1, 'd'),
                    },
                ],

                last_4_weeks : [
                    {
                        d1: moment().startOf('day').subtract(28,'d'),
                        d2: moment().startOf('day').subtract(22,'d')
                    },
                    {
                        d1: moment().startOf('day').subtract(21,'d'),
                        d2: moment().startOf('day').subtract(15,'d')
                    },
                    {
                        d1: moment().startOf('day').subtract(14,'d'),
                        d2: moment().startOf('day').subtract(8,'d')
                    },
                    {
                        d1: moment().startOf('day').subtract(7,'d'),
                        d2: moment().startOf('day')
                    }
                ],

                from_2016 : monthsFrom2016
            };

            return periods[key];
        },

        renderMenu: function(model) {
            var menu = Handlebars.compile(Menu);
            $('.menu-detail').html(menu({obj: model.toJSON()}));
        }

    };
})();

