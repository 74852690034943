var Backbone = require('backbone');
var Handlebars = require('handlebars');
var Template = require('../templates/score.html');
var Utils = require('../trash/utils');
Handlebars.registerHelper('getStype', function(stypes, key, options) {
    return stypes[key];
});

module.exports = Backbone.View.extend({
    tagName: 'tr',

    events: {
        'click .remove-channel': 'removeChannel',
        'click .edit-price' : 'editPrice',
        'submit .changePriceForm' : 'changePrice',
        'click .close-form-change-price' : 'closePrice'
    },

    removeChannel: function() {
        var that = this;
        bootbox.confirm("Вы точно хотите удалить счет?", function(result) {
            if (result) {
                that.model.destroy()
                    .done(function () {
                        NProgress.done();
                        that.$el.remove();
                    });
            }
        });
    },

    initialize: function(options) {
        this.stypes = options.stypes.toJSON();
        this.render();
    },

    // Активация формы изменения цены и работа с формой через класс .toggle-change-price-on
    editPrice: function (ev) {
        $(ev.currentTarget).parent().find(' > form').addClass('toggle-change-price-on active');
    },

    // Изменение цены
    changePrice: function (ev) {
        ev.preventDefault();
        var that = this;

        var data = $(ev.target).serializeObject();

        this.model.save(data).done(function () {
            that.render();
        });

        //this.$('.toggle-change-price-on').removeClass('active');
    },

    // Закрытие активной формы изменения цены
    closePrice: function () {
        this.$('.toggle-change-price-on').removeClass('active');
    },

    render: function() {
        var template = Handlebars.compile(Template);
        this.$el.html(template({obj: this.model.toJSON(), stypes: this.stypes}));
    }
});