var Backbone = require('backbone');
var Handlebars = require('handlebars');
var context = require('../models/context');
var LoginTpl = require('../templates/login.html');

module.exports = Backbone.View.extend({
    el: $('body'),

    initialize: function () {
        this.$el.removeClass();
        this.$el.empty();
        this.render();
    },

    events: {
        'submit #contact': 'login',
    },

    login: function (ev) {
        ev.preventDefault();
        NProgress.start();
        var data = $(ev.target).serializeObject();
        var user = context.get('user');
        user.save(data)
            .done(function (data) {
                if (user.get('is_authenticated') === true) {
                    Backbone.history.navigate('#', true);
                }
            });
    },

    render: function () {
        var template = Handlebars.compile(LoginTpl);
        this.$el.html(template());
        this.$el.addClass('external-page sb-l-c sb-r-c');
    },

    deactivate: function() {
        this.stopListening();
        this.undelegateEvents();
        return this;
    }

});
