var Backbone = require('backbone');
var ClientModel = require('../models/client_slug');
var Utils = require('../trash/utils');
var BaseView = require('../views/baseEffectView');
var Tpl = require('../templates/link.html');

module.exports = BaseView.extend({
    el: $('body'),

    preload: function(options) {
        this.el = $('body');
        this.template = Tpl;
        this.model = new ClientModel({slug: options.slug});
    }
});