var Backbone = require('backbone');
var Handlebars = require('handlebars');

module.exports = Backbone.View.extend({
    initialize: function () {
    },

    render: function () {
    },

    deactivate: function() {
        this.$el.removeClass();
        this.$el.empty();
        this.stopListening();
        this.undelegateEvents();
        return this;
    }
});
