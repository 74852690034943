var Backbone = require('backbone');
var Handlebars = require('handlebars');
var CPopup = require('../templates/popups/calltouch.html');
var MPopup = require('../templates/popups/mango.html');
var DPopup = require('../templates/popups/login_direct.html');
var APopup = require('../templates/popups/login_adwords.html');
var FPopup = require('../templates/popups/facebook_ad_account.html');
var MetrikaPopup = require('../templates/popups/metrika_update_popup.html');
var MetrikaCounterPopup = require('../templates/popups/metrica_counter.html');
var ClTpl = require('../templates/client.html');

module.exports = Backbone.View.extend({
    tagName: 'tr',

    initialize: function (opt) {
        this.render();
    },

    events: {
        'click .remove-client': 'del',
        'click .metrika': 'metrikaOauth',
        'click .metrika-update': 'metrikaUpdate',

        'click .metrika-counter': 'metrikaCounter',

        'click .direct': 'directOauth',
        'click .direct-update': 'directUpdate',

        'click .login-direct': 'loginDirect',
        'click .login-adwords': 'loginAdwords',

        'click .facebook-ad-account': 'facebookAdAccount',

        'click .calltouch': 'calltouch',

        'click .mango': 'mango',

        'click .google-unlock,.google': 'googleOauth',
        'click .google-update': 'googleUpdate',

        'click .lock': 'render',
        'submit .metrika-counter-form': 'submit_metrika_counter',
        'submit .calltouch-key': 'submit_calltouch',
        'submit .mango-key': 'submit_mango',
        'submit .direct_form': 'submit_direct',
        'submit .adwords_form': 'submit_adwords',
        'submit .facebook_form': 'submit_facebook',
    },

    metrikaOauth: function (ev) {
        var that = this;
        this.model.save({metrikaOauth: true})
            .done(function() {
                location.href = that.model.get('redirect');
            });
    },

    metrikaUpdate: function(ev) {
        var popup = Handlebars.compile(MetrikaPopup);
        this.$el.find('.metrika-update-td').html(popup());
        this.$el.find('.unlock').addClass('metrika');
    },

    metrikaCounter: function(ev) {
        var popup = Handlebars.compile(MetrikaCounterPopup);
        this.$el.find('.metrika-counter-td').html(popup({obj: this.model.toJSON()}));
    },

    directOauth: function (ev) {
        var that = this;
        this.model.save({directOauth: true})
            .done(function() {
                location.href = that.model.get('redirect');
            });
    },

    directUpdate: function(ev) {
        var popup = Handlebars.compile(MetrikaPopup);
        this.$el.find('.direct-update-td').html(popup());
        this.$el.find('.unlock').addClass('direct');
    },

    googleOauth: function (ev) {
        var that = this;
        this.model.save({googleOauth: true})
            .done(function () {
                location.href = that.model.get('redirect');
            });
    },

    googleUpdate: function(ev) {
        var popup = Handlebars.compile(MetrikaPopup);
        this.$el.find('.google-update-td').html(popup());
        this.$el.find('.unlock').addClass('google');
    },

    loginDirect: function(ev) {
        var popup = Handlebars.compile(DPopup);
        this.$el.find('.direct-td').html(popup({obj: this.model.toJSON()}));
    },

    loginAdwords: function(ev) {
        var popup = Handlebars.compile(APopup);
        this.$el.find('.adwords-td').html(popup({obj: this.model.toJSON()}));
    },

    facebookAdAccount: function (ev) {
        var popup = Handlebars.compile(FPopup);
        this.$el.find('.facebook-td').html(popup({obj: this.model.toJSON()}));
    },

    submit_metrika_counter: function (ev) {
        var that = this;
        ev.preventDefault();
        var data = $(ev.target).serializeObject();
        this.model.save(data)
            .done(function () {
                that.render();
            });
    },

    submit_direct: function (ev) {
        var that = this;
        ev.preventDefault();
        var data = $(ev.target).serializeObject();
        this.model.save(data)
            .done(function () {
                that.render();
            });
    },

    submit_adwords: function (ev) {
        var that = this;
        ev.preventDefault();
        var data = $(ev.target).serializeObject();
        this.model.save(data)
            .done(function () {
                that.render();
            });
    },

    submit_facebook: function (ev) {
        var that = this;
        ev.preventDefault();
        var data = $(ev.target).serializeObject();
        this.model.save(data)
            .done(function () {
                that.render();
        });
    },

    calltouch: function (ev) {
        var popup = Handlebars.compile(CPopup);
        this.$el.find('.calltouch-td').html(popup({obj: this.model.toJSON()}));
    },


    mango: function(ev) {
        var popup = Handlebars.compile(MPopup);
        this.$el.find('.mango-td').html(popup({obj: this.model.toJSON()}));
    },


    submit_calltouch: function (ev) {
        var that = this;
        ev.preventDefault();
        var data = $(ev.target).serializeObject();
        this.model.save(data)
            .done(function () {
                that.render();
            });
    },

    submit_mango: function (ev) {
        var that = this;
        ev.preventDefault();
        var data = $(ev.target).serializeObject();
        this.model.save(data)
            .done(function () {
                that.render();
            });
    },

    del: function (ev) {
        var that = this;
        bootbox.confirm("Вы точно хотите удалить клиента?", function(result) {
            if (result) {
                that.model.destroy()
                    .done(function () {
                        that.$el.remove();
                    });
            }
        });
    },

    render: function () {
        var template = Handlebars.compile(ClTpl);
        this.$el.html(template({obj: this.model.toJSON()}));
    }
});
