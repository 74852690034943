var _ = require('underscore');

function Translator () {
    this.dics = {
        "а": "a",
        "б": "b",
        "в": "v",
        "г": "g",
        "д": "d",
        "е": "e",
        "ё": "jo",
        "ж": "zh",
        "з": "z",
        "и": "i",
        "й": "ji",
        "к": "k",
        "л": "l",
        "м": "m",
        "н": "n",
        "о": "o",
        "п": "p",
        "р": "r",
        "с": "s",
        "т": "t",
        "у": "u",
        "ф": "f",
        "х": "kh",
        "ц": "tss",
        "ч": "ch",
        "ш": "sh",
        "щ": "sch",
        "ъ": "",
        "ы": "y",
        "ь": "",
        "э": "eh",
        "ю": "yu",
        "я": "ya",
        " ": "_",
    };

    this.getName = function(name) {
        var that = this;
        name = name.toLowerCase();
        return _.map(name, function(symbol) {
            return symbol in that.dics ? that.dics[symbol] : symbol;
        }).join('');
    };
}

module.exports = new Translator();