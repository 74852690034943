var c3 = require('c3');
var Handlebars = require('handlebars');
var _ = require('underscore');
var Tpl = require('../templates/graph.html');

module.exports = Backbone.View.extend({
		
	el: '#graph-container',

	events: {
		'click a': 'changeChanel'
	},

	initialize: function(data) {
		var that = this;
		this.data = data;
		this.select = [];

		for (var key in data) {
			this.select.push(key);
		}

		var main;
		for (var i in this.data) {
		    main = this.data[i];
		    break;
		}		
		this.selected = main;
		this.dataСollection(main);
		this.render();
	},

	changeChanel: function (ev) {
		var chanel = $(ev.target).attr('data-value');
		this.selected = chanel;
		for (var key in this.data) {
			if (key === chanel) {
				this.dataСollection(this.data[key]);
				this.render();
			}	
		}
	},

	dataСollection: function(data) {
		var that = this;
		this.endCharData = {
			periods: ['x'],
			phone: ['Стоимость'],
			cart: ['Обращений'],
			score: ['Расход']
		};

		for (var key in data) {
			this.endCharData.periods.push(key);
		}

		_.each(data, function (item) {
			that.endCharData.phone.push(item.phone);
			that.endCharData.cart.push(item.cart);
			that.endCharData.score.push(item.score);
		});
	},	

	render: function () {
		var that = this;
		var template = Handlebars.compile(Tpl);
		this.$el.html(template({
			select: this.select,
			sel: this.selected
		}));

		var chart = c3.generate({
		 	bindto: '#c3-graph',
		    data: {
		        x : 'x',
		        columns: [
		            that.endCharData.periods,
		            that.endCharData.phone,
		            that.endCharData.cart,
		            that.endCharData.score
		        ],
		        groups: [
		            ['Обращений', 'Стоимость', 'Расход']
		        ]
		    },
		    axis: {
		        x: {
		            type: 'category'
		        }
		    }
		});	
		return this;
	},

	deactivate: function () {
        $('.graph-modal').remove();
        this.stopListening();
        this.undelegateEvents();
        return this;
	}
});