var Handlebars = require('handlebars');
var context = require('../models/context');
var Core = require('../trash/core');
var BaseTpl = require('../templates/base.html');

module.exports = Backbone.View.extend({
    el: $('body'),

    initialize: function (opt) {

        this.$el.removeClass();
        this.$el.empty();

        this.render(opt);

        var defaults = {
            sbl: "sb-l-o",
            sbr: "sb-r-c",
            collapse: "sb-l-m",
        };
        this.options = $.extend({}, defaults);
        if ($(window).width() < 1080 && !this.$el.hasClass('mobile-view')) {
            this.$el.removeClass('sb-r-o').addClass('mobile-view sb-l-m sb-r-c');
        } else if ($(window).width() > 1080) {
            this.$el.removeClass('mobile-view');
        }
    },

    events: {
        'click #toggle_sidemenu_l': 'sidebarLeftToggle',
    },

    triggerResize: function() {
        var that = this;
        setTimeout(function() {
            $(window).trigger('resize');
            if(that.$el.hasClass('sb-l-m')) {
                that.$el.addClass('sb-l-disable-animation');
            }
            else {
                that.$el.removeClass('sb-l-disable-animation');
            }
        }, 300);
    },

    sidebarLeftToggle: function() {
        if ($('body.sb-top').length) { return; }

        if (this.$el.hasClass('sb-l-c') && this.options.collapse === "sb-l-m") {
            this.$el.removeClass('sb-l-c');
        }
        this.$el.toggleClass(this.options.collapse).removeClass('sb-r-o').addClass('sb-r-c');
        this.triggerResize();
    },

    render: function (opt) {
        var template = Handlebars.compile(BaseTpl);
        this.$el.html(template({user: context.get('user').toJSON()}));
        this.$el.addClass('dashboard-page');
        this.renderChild(opt);
    },

    renderChild: function (opt) {
        var View = opt.view;
        var pk = opt.pk;
        if (this.childView)
            this.childView.deactivate();
        this.childView = new View({el: this.$el.find('#content'), pk: pk});
    },

    deactivate: function() {
        this.stopListening();
        this.undelegateEvents();
        return this;
    }
});
