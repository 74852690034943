var Backbone = require('backbone');
var BaseView = require('../app/views/base');
var HomeView = require('../app/views/home');
var LoginView = require('../app/views/login');
var ClientView = require('../app/views/client_detail');
var EffectView = require('../app/views/effect');
var UTMView = require('../app/views/utm');
var ClientsView = require('../app/views/clients');
var ScoresView = require('../app/views/scores');
var LinkView = require('../app/views/link');
var ChangerView = require('../app/views/changer');
var moment = require('moment');
var context = require('../app/models/context');

module.exports = Backbone.Router.extend({
    routes: {
        '': 'clients',
        'login': 'login',
        'logout': 'logout',
        'clients': 'clients',
        'clients/:id': 'client',
        'clients/:id/effect': 'effect',
        'clients/:id/utm': 'utm',
        'clients/:id/scores': 'scores',
        'link/:slug': 'link',
        'utm_changer': 'utm_changer',
    },

    notAuthView: function (View) {
        moment.locale('ru');
        if (context.get('user').get('is_authenticated')) {
            Backbone.history.navigate('', {trigger: true});
            return false;
        }
        if (this.baseView){
            this.baseView.deactivate();
            this.baseView = undefined;
        }
        if (this.currentView)
            this.currentView.deactivate();
        this.currentView = new View();
    },

    authView: function (View, pk) {
        moment.locale('ru');
        if (!(context.get('user').get('is_authenticated'))) {
            Backbone.history.navigate('#login', true);
            return false;
        }

        if (this.currentView)
            this.currentView.deactivate();

        // Базовая вьюха
        if (!(this.baseView))  {
            this.baseView = new BaseView({view: View, pk: pk});
        } else {
            this.baseView.renderChild({view: View, pk: pk});
        }
    },

    logout: function (View) {
        if (context.get('user').get('id')) {
            context.get('user').save({'logout': true})
                .done(function () {
                    Backbone.history.navigate('#login', true);
                });
        } else Backbone.history.navigate('#login', true);
    },

    home: function () {
        this.authView(HomeView);
    },

    clients: function () {
        this.authView(ClientsView);
    },

    client: function(pk) {
        this.authView(ClientView, pk);
    },

    effect: function (pk) {
        this.authView(EffectView, pk);
    },

    utm: function (pk) {
        this.authView(UTMView, pk);
    },

    scores: function (pk) {
        this.authView(ScoresView, pk);
    },

    login: function () {
        this.notAuthView(LoginView);
    },

    link: function(slug) {
        moment.locale('ru');
        this.currentView = new LinkView({slug: slug});
    },

    utm_changer: function() {
        this.currentView = this.authView(ChangerView);
    }
});
