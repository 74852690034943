var $ = global.jQuery = window.jQuery = require('jquery');
var Backbone = require('backbone');
var Handlebars = require('handlebars');
var Router = require('./router');
var UserModel = require('../app/models/user');
var ErrorsTpl = require('../app/templates/errors.html');
var NProgress = require('nprogress');
var context = require('../app/models/context');

module.exports = (function () {
    return function () {
        $.fn.serializeObject = function() { var o = {}; var a = this.serializeArray(); $.each(a, function() { if (o[this.name] !== undefined) { if (!o[this.name].push) { o[this.name] = [o[this.name]]; } o[this.name].push(this.value || ''); } else { o[this.name] = this.value || ''; } }); return o; };

        NProgress.configure({
            minimum: 0.15,
            trickleRate: 0.07,
            trickleSpeed: 360,
            showSpinner: false,
            barColor: 'npr-primary',
            barPos: ''
        });

        // Обновляем метод parse для коллекции и модели
        var parse = function(resp, xhr) { if (resp.data) return resp.data; else return resp;};
        Backbone.Collection.prototype.parse = parse;
        Backbone.Model.prototype.parse = parse;

        var oldSync = Backbone.sync;
        Backbone.sync = function(method, model, options){
            // Добавляем заголовок
            options.beforeSend = function(xhr){
                xhr.setRequestHeader('X-CSRFToken', $('meta[name="csrf-token"]').attr('content'));
            };
            // Задаем новый deffered и дефолтный sync
            var sync = oldSync(method, model, options);
            var deferred = new $.Deferred();

            // Запускаем NProgress
            NProgress.start();
            sync.done(function (data) {
                // Обработка ответа
                if (data.errors) {
                    deferred.reject(data);
                    var template = Handlebars.compile(ErrorsTpl);
                    bootbox.alert(template({errors: data.errors}));
                }
                else {
                    deferred.resolve(data.data);
                }
                NProgress.done();
            })
                .fail(function () {
                    bootbox.alert('Ошибка при запросе, проверьте соединение с интернетом');
                    NProgress.done();
                });
            return deferred.promise();
        };

        var user = new UserModel();
        $.when(user.fetch())
            .done(function () {
                context.set({user: user});
                var router = new Router();
                Backbone.history.start();
            })
            .fail(function () {
                bootbox.alert('Ошибка при получении юзера');
            });
    };
})();