var Model = require('../models/score');

var Scores = Backbone.Collection.extend({
    model: Model,
    url: '/api/scores/',
    byDate: function(date) {
        if (date !== "") {
            filtered = this.filter(function(score) {
                return score.get('mdate') === date;
            });
            return new Scores(filtered);
        } else {
            console.log('Тут Дата');
            return this;
        }
    },
    byChannel: function(channel_id) {
        if (channel_id !== "") {
            filtered = this.filter(function(score) {
                return score.get('channel_id') == channel_id;
            });
            return new Scores(filtered);
        } else {
            return this;
        }
    },
    bySType: function(s_type) {
        if (s_type !== "") {
            filtered = this.filter(function(score) {
                return score.get('s_type') == s_type;
            });
            return new Scores(filtered);
        } else {
            return this;
        }
    }
});

module.exports = Scores;