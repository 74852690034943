var Handlebars = require('handlebars');
var Translator = require('../trash/translator');
var Tpl = require('../templates/utm_changer.html');
var getLocation = function(href) { var l = document.createElement("a"); l.href = href; return l; };
var _ = require('underscore');

function getJsonFromUrl(query, without) {
    var result = {};
    if (!(without))
        query = query.substr(1);
    query.split("&").forEach(function(part) {
        var item = part.split("=");
        if ((item[0] !== undefined) & (item[1] !== undefined))
            result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
}

function getUrlFromJson(result) {
    var url = _.map(result, function(value, key) {
        if (key) return key + '=' + value;
    });
    return '?' + url.join('&');
}

module.exports = Backbone.View.extend({
    el: $('#content'),

    initialize: function() {
        this.render();
    },

    events: {
        'submit .changer': 'change',
        'click .yandex': 'pre_yandex',
        'click .google': 'pre_google',
    },

    pre_yandex: function() {
        $('input[name=source]').val('yandex_cpc');
        $('input[name=content]').val('ctr_{ad_id}|ph_{phrase_id}|pst_{position_type}|ps_{position}|matchtype_{addphrases}_{addphrasestext}|network_{source_type}|placement_{source}RRRcamp_id_{campaign_id}');
    },

    pre_google: function() {
        $('input[name=source]').val('google');
        $('input[name=content]').val('RRRcamp_id_{campaignid}');
    },

    change: function(ev) {  
        $('.output').empty();
        ev.preventDefault();
        var data = $(ev.target).serializeObject();
        data.translate = Translator.getName(data.campaign);
        data.rows = data.urls.split('\n');
        data.params = getJsonFromUrl(data.params, true);

        var table = $('<table class="table table-bordered" />');

        _.each(data.rows, function(row) {
            var urls = row.split("\t");
            var trow = $('<tr />');
            var link = getLocation(urls[0]);

            var params = getJsonFromUrl(link.search);

            params.utm_source = data.source;
            params.utm_medium = data.medium;
            params.utm_campaign = data.translate; 
            if (data.term)
                params.utm_term = data.term;
            if (data.content)
                params.utm_content = data.content;
            $.extend(params, data.params);
            var result = getUrlFromJson(params) + link.hash;
            console.log(link.protocol);    
            trow.append('<td>' + result + '</td>');

            var results = [];
            _.each(urls, function (url, value) {
                if (value > 0 & value < 5 & url.indexOf("http") > -1) {
                    var link = getLocation(url);
                    var params = getJsonFromUrl(link.search);
                    params.utm_source = data.source;
                    params.utm_medium = data.medium;
                    params.utm_campaign = data.translate;
                    if (data.term)
                        params.utm_term = data.term + '_dop_url_' + value;
                    if (data.content)
                        params.utm_content = data.content;
                    $.extend(params, data.params);
                    results.push(getUrlFromJson(params) + link.hash);
                }
            });
            trow.append('<td>' + results.join(' || ') + '</td>');
            table.append(trow);
        });

        $('.output').html(table);
    },

    render: function() {
        var template = Handlebars.compile(Tpl);
        this.$el.html(template());
    },

    deactivate: function() {
        this.$el.empty();
        this.stopListening();
        this.undelegateEvents();
        return this;
    }
});