var Backbone = require('backbone');
var Handlebars = require('handlebars');
var moment = require('moment');
var ScoreView = require('../views/score');
var ClientModel = require('../models/client');
var STypeModel = require('../models/s_type');
var ScoreModel = require('../models/score');
var ResetModel = require('../models/resetScores');
var ScoresCollection = require('../collections/score');
var Utils = require('../trash/utils');
var _ = require('underscore');
var Tpl = require('../templates/scores.html');
$.fn.datetimepicker = require('eonasdan-bootstrap-datetimepicker');

module.exports = Backbone.View.extend({
    initialize: function (options) {
        var that = this;
        this.model = new ClientModel({id: options.pk});
        this.options = options;
        this.scores = new ScoresCollection();
        this.stypes = new STypeModel();
        $.when(
            this.scores.fetch({ data: $.param({client: this.options.pk}) }),
            this.model.fetch(),
            this.stypes.fetch()
        )
            .done(function() {
                that.model = Utils.arrayToCollection(that.model);
                that.render();
                NProgress.done();
            });
        Utils.renderMenu(this.model);
    },

    events: {
        'submit .add_score': 'addScore',
        'change #date': 'filter',
        'change #channel_id': 'filter',
        'click .client_scores__reset': 'resetScores',
    },

    resetScores: function(ev) {
        ev.preventDefault();
        var model = new ResetModel({id: this.model.get('id')});
        model.save()
            .done(function() {
                bootbox.alert('Обновление счетов запущено. Обновление будет выполнено в течение нескольких минут');
                NProgress.done();
            });
    },

    addScore: function(ev) {
        var that = this;
        ev.preventDefault();
        var data = $(ev.target).serializeObject();
        if ($.isNumeric(data.res)) {
            data.channel_id = data.res;
            data.s_type = 'utm';
        }
        else
            data.s_type = data.res;

        var model = new ScoreModel(data);
        model.save()
            .done(function() {
                that.scores.add(model);
                that.render();
                NProgress.done();
            });
    },

    filter: function(ev) {
        var date = $("#date").val();
        var channel_id = $("#channel_id").val();
        var scores = this.scores.byDate(date);
        if ($.isNumeric(channel_id))
            scores = scores.byChannel(channel_id);
        else
            scores = scores.bySType(channel_id);
        this.renderScores(scores);
    },

    getChannelDates: function() {
        var that = this;
        this.dates = [];

        this.scores.each(function (model) {
            model.set('mdate', moment(model.get('date'), 'YYYY-MM-DD').format('MMMM YYYY'));
            that.dates.push(model.get('mdate'));
            if ($.isNumeric(model.get('channel'))) {
                model.set('channel', that.model.get('channel').get(model.get('channel_id')).get('name'));
            }
        });
        this.dates = _.uniq(that.dates);
    },

    renderScores: function(scores) {
        var that = this;
        $('.scores tbody').empty();
        scores.each(function (model) {
            var view = new ScoreView({model: model, stypes: that.stypes});
            $('.scores tbody').append(view.$el);
        });
    },

    render: function() {
        var that = this;
        this.getChannelDates();

        var template = Handlebars.compile(Tpl);

        $('#content').html(template({
            obj: this.model.toJSON(),
            channels: this.model.get('channel').toJSON(),
            dates: this.dates,
            stypes: this.stypes.toJSON()
        }));

        this.renderScores(this.scores);

        $('input[name=date]').datetimepicker({
            format: 'YYYY-MM-DD'
        });
    },

    deactivate: function () {
        $('.menu-detail').empty();
        $('#content').empty();
        this.stopListening();
        this.undelegateEvents();
        return this;
    }
});
