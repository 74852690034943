var _ = require('underscore');
var Backbone = require('backbone');
var Handlebars = require('handlebars');
var moment = require('moment');
var daterangepicker = require('daterangepicker');
var DataTable = require('datatables');
var ResetModel = require('../models/resetLeads');
var LeadCollection = require('../collections/lead');
var Utils = require('../trash/utils');
var PeriodView = require('../views/period');
var GraphView = require('../views/graph');
var EffectTag = require('../trash/effect_tags');
Handlebars.registerHelper('ifEqual', function(v1, v2, options) { if(v1 === v2) return options.fn(this); return options.inverse(this); });
require('datatables.net-fixedheader')(window, $);

module.exports = Backbone.View.extend({
    initialize: function(options) {
        m = moment;
        this.preload(options);
        this.options = options;
        this.periods = Utils.periods();
        this.leads = new LeadCollection();
        this.main();
        this.listenTo(this.periods, 'remove', this.updatePeriods);
        this.listenTo(this.periods, 'change', this.updatePeriods);
    },

    events: {
        'click .client_leads__main': 'main',
        'click .client_leads__direct': 'direct',
        'click .client_leads__adwords': 'adwords',
        'click .client_leads__facebook': 'facebook',
        'click .client_leads__reset': 'updateEffect',
        'submit .addPeriod': 'addPeriod',
        'change #change-period' : 'changePeriod'
    },

    generateDataChar : function () {
        var dataChar = {};
        for (var key in this.data) {
            var from = key.search('</div>');
            var to = key.length;
            var newStr = key.substring(from,to).slice(6);
            dataChar[newStr] = {};
            dataChar[newStr] = this.data[key];
        }
        return dataChar;
    },

    addPeriod: function(ev) {
        ev.preventDefault();
        var data = $(ev.target).serializeObject();
        var periods = data.daterangebase.split(' - ');
        var model = new Backbone.Model({
            d1: moment(periods[0], "YYYY-MM-DD"),
            d2: moment(periods[1], "YYYY-MM-DD")
        });
        this.periods.add(model);
        if (this.filter == 'main')
            this.formatLeads();
        else
            this.formatDetailLeads();

        this.render();
    },

    changePeriod : function (ev) {
        var key = this.currentPeriod = $(ev.target).val();
        this.periods.reset(Utils.changePeriods(key));

        if (this.filter == 'main')
            this.formatLeads();
        else
            this.formatDetailLeads();

        this.render();
    },

    updatePeriods: function() {
        if (this.filter == 'main')
            this.formatLeads();
        else
            this.formatDetailLeads();
        this.render();
    },

    main: function() {
        var that = this;
        this.filter = 'main';
        this.model.fetch()
            .done(function() {
                that.model = Utils.arrayToCollection(that.model);
                that.leads.fetch({ data: $.param({client: that.model.get('id')}) })
                    .done(function() {
                        that.formatLeads();
                        that.render();
                    });
            });
    },

    direct: function() {
        var that = this;
        this.filter = 'direct';
        this.leads.fetch({data: $.param({client: this.model.get('id'), direct: true})})
            .done(function() {
                that.formatDetailLeads();
                that.render();
            });
    },

    adwords: function() {
        var that = this;
        this.filter = 'adwords';
        this.leads.fetch({data: $.param({client: this.model.get('id'), adwords: true})})
            .done(function() {
                that.formatDetailLeads();
                that.render();
            });
    },

    facebook: function() {
        var that = this;
        this.filter = 'facebook';
        this.leads.fetch({data: $.param({client: this.model.get('id'), facebook: true})})
            .done(function() {
                that.formatDetailLeads();
                that.render();
            });
    },

    formatDetailLeads: function() {
        // Формирование лидов
        var that = this;

        data = _.groupBy(this.leads.toJSON(), function (lead) {
            return lead.campaign;
        });

        this.data = {};

        _.each(data, function (value, from) {
            that.data[from] = {};
            that.periods.each(function (period) {
                var key = 'С ' + period.get('d1').format('YYYY-MM-DD') + ' по ' + period.get('d2').format('YYYY-MM-DD');
                var arr = _.filter(value, function (item) {
                    var date = moment(item.date, "YYYY-MM-DD");
                    return date.diff(period.get('d1'), 'days') >= 0 && date.diff(period.get('d2'), 'days') <= 0;
                });

                that.data[from][key] = {
                    'cart': _.reduce(arr, function(memo, num){ return memo + num.cart; }, 0),
                    'phone': _.reduce(arr, function(memo, num){ return memo + num.phone; }, 0),
                    'score': _.reduce(arr, function(memo, num){ return memo + num.score; }, 0),
                };
            });
        });
    },

    formatLeads: function() {
        // Формирование лидов
        var that = this;

        var data = _.groupBy(this.leads.toJSON(), function (lead) {
            if (lead.s_type == 'utm') {
                var channel = that.model.get('channel').get(lead.channel);
                return (channel !== undefined) ? channel.get('name') : '<div style="display: none">ЯЯ1</div>Не определен';
            }
            if (lead.s_type == 'direct')
                return '<div style="display: none">2</div>Прямые заходы';
            if (lead.s_type == 'organic')
                return '<div style="display: none">1</div>Заходы с поиска';
            if (lead.s_type == 'other')
                return '<div style="display: none">3</div>Прочие заходы';
            if (lead.s_type == 'ref')
                var channel = that.model.get('channel').get(lead.channel);
                return (channel !== undefined) ? channel.get('name') : '<div style="display: none">4</div>Переходы по ссылкам';
            return '<div style="display: none">ЯЯ2</div>Оставшиеся';
        });

        this.data = {};

        _.each(data, function (value, from) {
            that.data[from] = {};
            that.periods.each(function (period) {
                var key = 'С ' + period.get('d1').format('YYYY-MM-DD') + ' по ' + period.get('d2').format('YYYY-MM-DD');
                var d1 = period.get('d1');
                var d2 = period.get('d2');
                var arr = _.filter(value, function (item) {
                    var date = moment(item.date, "YYYY-MM-DD");
                    return date.isBetween(d1, d2) || date.isSame(d1) || date.isSame(d2);
                });

                that.data[from][key] = {
                    'cart': _.reduce(arr, function(memo, num){ return memo + num.cart; }, 0),
                    'phone': _.reduce(arr, function(memo, num){ return memo + num.phone; }, 0),
                    'score': _.reduce(arr, function(memo, num){
                        var date = moment(num.date, "YYYY-MM-DD");
                        var score = (date.month() == moment().month() && num.s_type != 'utm') ? num.score / moment().endOf('month').date() * moment().date() : num.score;
                        return memo + score;
                    }, 0),
                };

            });
        });

        var result = {};

        this.periods.each(function(period) {
            var key = 'С ' + period.get('d1').format('YYYY-MM-DD') + ' по ' + period.get('d2').format('YYYY-MM-DD');
            result[key] = {cart: 0, phone: 0, score: 0};
            _.map(that.data, function(value) {
                if (value[key]) {
                    result[key].phone += value[key].phone;
                    result[key].cart += value[key].cart;
                    result[key].score += value[key].score;
                }
            });
        });
        that.data['<div style="display: none">ЯЯ3</div>Общее'] = result;
    },

    updateEffect: function(ev) {
        ev.preventDefault();
        var model = new ResetModel({id: this.model.get('id')});
        model.save()
            .done(function() {
                bootbox.alert('Обновление лидов запущено. Обновление будет выполнено в течение нескольких минут');
                NProgress.done();
            });
    },

    setDataTablesOpt: function() {
        var that = this;

        $.extend($.fn.dataTableExt.oSort, {
            "num-html-pre": function ( a ) {
                var x = String(a).replace( /<[\s\S]*?>/g, "" );
                x = x.replace(/&nbsp;/g, '');
                return parseFloat( x );
            },

            "num-html-asc": function ( a, b ) {
                return ((a < b) ? -1 : ((a > b) ? 1 : 0));
            },

            "num-html-desc": function ( a, b ) {
                return ((a < b) ? 1 : ((a > b) ? -1 : 0));
            }
        } );

        return {
            columnDefs: [
                {
                    type: 'num-html',
                    targets: _.range(1, that.periods.length*3 + 1),
                },
            ],
            bPaginate: false,
            bLengthChange: false,
            bFilter: false,
            bSort: true,
            bInfo: false,
            bAutoWidth: false,
            scrollY: false,
            scrollX: true,
            scrollCollapse: true,
            sScrollX: "100%",
            sScrollXInner: "150%",
            paging: false,
            fixedColumns:   {
                leftColumns: 1,
            },
            order: [[0, 'asc']],
        };

    },

    renderPeriods: function() {
        $('.effect-table thead').append('<tr class="primary"><td rowspan="2">Канал</td></tr>');
        this.periods.each(function(model) {
            var view = new PeriodView({model: model});
            $('.effect-table thead tr:first-child').append(view.$el);
        });
        $('.effect-table thead').append('<tr class="primary"></tr>');
        this.periods.each(function(model) {
            $('.effect-table thead tr:last-child').append('<td align="center">Обращений</td><td align="center">Стоимость</td><td align="center">Расход</td> ');
        });
    },

    render: function() {
        var that = this;

        var template = Handlebars.compile(this.template);
        this.el.html(template({
            difference: this.difference,
            filter: that.filter,
            obj: this.model.toJSON(),
            data: this.data,
            dates: this.periods.toJSON(),
            currentPeriod : this.currentPeriod
        }));

        $('input[name=daterangebase]').daterangepicker({
            locale: {
                format: "YYYY-MM-DD",
            },
            opens: "left"
        });

        $('input[name=daterangebase]').on('apply.daterangepicker', function(ev, picker) {
            that.addPeriod(ev);
        });

        this.renderPeriods();

        var datatables_options = this.setDataTablesOpt();
        $('.effect-table').DataTable(datatables_options);
    },

    deactivate: function() {
        $('.menu-detail').empty();
        $('#content').empty();
        this.stopListening();
        this.undelegateEvents();
        return this;
    }
});
