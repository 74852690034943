var Backbone = require('backbone');
var Handlebars = require('handlebars');
var GoalView = require('../views/goal');
var ClientModel = require('../models/client');
var GoalCollection = require('../collections/goal');
var Tpl = require('../templates/client_detail.html');
var Utils = require('../trash/utils');
var $ = require('jquery');

module.exports = Backbone.View.extend({
    el: $('body'),

    events: {
        'click .metrika_goals__reset': 'goalsReset',
    },

    initialize: function(options) {
        var that = this;
        this.model = new ClientModel({id: options.pk});
        this.model.fetch()
            .done(function() {
                that.arrayToCollection();
                that.render();
            });
        Utils.renderMenu(this.model);
    },

    goalsReset: function(ev) {
        var that = this;
        ev.preventDefault();
        this.model.save({refresh_goals: true})
            .done(function () {
                that.arrayToCollection();
                that.render();
            });
    },

    arrayToCollection: function() {
        var collection = new GoalCollection();
        collection.reset(this.model.get('goals'));
        this.model.set({goals: collection});
    },

    render: function() {
        var that = this;

        var template = Handlebars.compile(Tpl);
        $('#content').html(template({obj: this.model.toJSON()}));

        this.model.get('goals').each(function (model) {
            var view = new GoalView({parentView: that, model: model});
            that.$el.find('.metrika tbody').append(view.$el);
        });
    },

    deactivate: function() {
        $('.menu-detail').empty();
        $('#content').empty();
        this.stopListening();
        this.undelegateEvents();
        return this;
    }
});