var Backbone = require('backbone');
var Utils = require('../trash/utils');
var ClientModel = require('../models/client');
var BaseView = require('../views/baseEffectView');
var Tpl = require('../templates/effect.html');

module.exports = BaseView.extend({
    preload: function(options) {
        this.options = options;
        this.el = $('#content');
        this.template = Tpl;
        this.model = new ClientModel({id: this.options.pk});
        Utils.renderMenu(this.model);
    }
});